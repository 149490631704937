export const environment = {
    env: 'develop',
    production: true,
    apiUrl: 'https://api.angelssword-local.net',
    domain: 'staging-rpg.angelssword-local.net',
    aesKey: 'rlP9hSxZttCpND2F3nrB9A',
    datadog: 'pub5322103a506273c89f0a97a0f6693b8c',
    datadogAppId: '29b97136-a47d-41b4-852c-5abfd8edf62e',
    datadogAppName: `angel's-sword-rpg---stg`
  };
  